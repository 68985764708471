import request from "@/utils/request";

// 获取订单列表
export function orderList(data) {
  return request({
    url: `/api/v1/platform/open/auotation/activity/appointment/manage/order/list`,
    method: "get",
    data
  });
}

// 恢复订单
export function recoverOrder(id) {
  return request({
    url: `/api/v1/platform/estate/house/order/manage/${id}/recover`,
    method: "post"
  });
}

// 取消订单
export function cancelOrder(id) {
  return request({
    url: `/api/v1/platform/estate/house/order/manage/${id}/cancel`,
    method: "post"
  });
}

// 确认收款
export function payedOrder(id, data) {
  return request({
    url: `/api/v1/platform/estate/house/order/manage/${id}/payed`,
    method: "post",
    data
  });
}

// 获取订单信息
export function orderDetail(id) {
  return request({
    url: `/api/v1/platform/estate/house/order/manage/${id}`,
    method: "post"
  });
}

// 获取成交订单列表
export function dealOrderList(data) {
  return request({
    url: `/api/v1/platform/customer/deal/list`,
    method: "post",
    data
  });
}

// 获取成交订单详情
export function dealOrderDetail(id) {
  return request({
    url: `/api/v1/platform/customer/deal/detail/${id}`,
    method: "post"
  });
}

// 修改成交订单
export function dealOrderEdit(data) {
  return request({
    url: `/api/v1/platform/customer/deal/edit`,
    method: "post",
    data,
    showLoading: false
  });
}

// 创建收款单
export function createReceipt(data) {
  return request({
    url: `/api/v1/platform/customer/deal/payment`,
    method: "post",
    data,
    showLoading: false
  });
}

// 成交订单状态修改
export function dealOrderStatus(data) {
  return request({
    url: `/api/v1/platform/customer/deal/status`,
    method: "post",
    data
  });
}

// 获取收款台账列表
export function receiptList(data) {
  return request({
    url: `/api/v1/platform/customer/deal/payment/list`,
    method: "post",
    data
  });
}

// 获取收款台账列表统计
export function receiptCount(data) {
  return request({
    url: `/api/v1/platform/customer/deal/payment/list/count`,
    method: "post",
    data
  });
}

// 编辑收款台账
export function editReceipt(data) {
  return request({
    url: `/api/v1/platform/customer/deal/payment/edit`,
    method: "post",
    data,
    showLoading: false
  });
}

// 删除收款列表
export function receiptDelete(id) {
  return request({
    url: `/api/v1/platform/customer/deal/payment/del/${id}`,
    method: "post"
  });
}

// 下拉数据动态查询
export function selectData() {
  return request({
    url: `/api/v1/platform/customer/deal/filter`,
    method: "post"
  });
}

// 渠道公司级联门店
export function mechanismBranch(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/cascade/branch`,
    method: "get",
    data
  });
}
// /api/v1/platform/customer/deal/list/count
export function dealCount(data) {
  return request({
    url: `/api/v1/platform/customer/deal/list/count`,
    method: "post",
    data
  });
}
