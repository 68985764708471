<template>
  <div class="order">
    <div class="x3 aic mb16">
      <div class="filter">
        <el-select
          v-model="query.openQuotationActivityId"
          placeholder="开盘活动"
          class="mr8"
          clearable
          @change="search"
        >
          <el-option
            v-for="item in activityList"
            :key="item.id"
            :label="item.activityName"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="query.marketActivityId"
          placeholder="营销活动"
          class="mr8"
          clearable
          @change="search"
        >
          <el-option
            v-for="item in marketActivityList"
            :key="item.id"
            :label="item.giftBagName"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="query.orderStatus"
          placeholder="订单状态"
          class="mr8"
          clearable
          @change="search"
        >
          <el-option
            v-for="item in orderStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-date-picker
          class="mr8"
          v-model="dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-input
          placeholder="订单编号或购房者姓名、手机号"
          v-model="query.keyWord"
          style="width: 240px;margin-right: 10px;"
          class="f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-checkbox v-model="query.loan" @change="search"
          >已申请金融方案</el-checkbox
        >
      </div>
      <el-button type="primary" @click="exportOrder">导出认购订单</el-button>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column label="单号/总价">
          <div slot-scope="scope" class="y4">
            <p class="blue pointer" @click="chooseDetail(scope.row)">
              {{ scope.row.orderNo }}
            </p>
            <p style="color:red">
              认购价¥{{ scope.row.totalPrice | toThousands }}
            </p>
            <p style="color:red">{{ scope.row.giftBagName }}</p>
          </div>
        </el-table-column>
        <el-table-column label="认购客户">
          <div slot-scope="scope">
            <p class="name">{{ scope.row.customerName }}</p>
            <p style="color:#999">
              {{ scope.row.customerPhone | formatPhone }}
            </p>
            <p style="color:#999">{{ scope.row.customerIdcard }}</p>
          </div>
        </el-table-column>
        <el-table-column label="认购房源/车位">
          <div slot-scope="scope">
            <p class="name">
              {{ scope.row.parkingHouseInfo.floorName }}
              {{ scope.row.parkingHouseInfo.areaName }}
              {{ scope.row.parkingHouseInfo.parkingPlaceNo }}号
            </p>
            <p style="color:#999">
              {{ scope.row.parkingHouseInfo.houseTypeName }}，建面
              {{ scope.row.parkingHouseInfo.buildingArea }}㎡
            </p>
          </div>
        </el-table-column>
        <el-table-column label="开盘活动">
          <div slot-scope="scope">
            <p>{{ scope.row.activityName }}</p>
            <p style="color:#999">{{ scope.row.activityKindName }}</p>
          </div>
        </el-table-column>
        <el-table-column label="金融方案">
          <div slot-scope="scope">
            {{ scope.row.loan ? "已申请" : "-" }}
          </div>
        </el-table-column>
        <el-table-column label="认购方式">
          <div slot-scope="scope">
            {{ scope.row.orderBuyType === 2 ? "竞拍认购" : "直接认购" }}
          </div>
        </el-table-column>
        <el-table-column label="认购金额">
          <div slot-scope="scope">
            <p style="color:red">¥{{ scope.row.orderPrice | toThousands }}</p>
          </div>
        </el-table-column>
        <el-table-column label="认购时间">
          <div slot-scope="scope">
            <p>
              {{ scope.row.inDate || "-" }}
            </p>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="query.pageNum"
        :page-sizes="pageSizes"
        :page-size="query.pageSize"
        layout="sizes, prev, pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <parkingDetail v-model="showDetail" :detail="activeItem"></parkingDetail>
  </div>
</template>

<script>
import parkingDetail from "./components/parkingDetail";
import { getActivityList, getMarketActivity } from "@/api/marketActivity";
import rules from "@/utils/rules";

import { orderList, recoverOrder, cancelOrder, payedOrder } from "@/api/order";
import { EXPORT_ORDER_URL } from "@/config";

const defaultForm = {
  tradeDate: "",
  tradeMsg: "",
  tradeNo: ""
};
export default {
  components: {
    parkingDetail
  },
  data() {
    return {
      rules,
      activeItem: {},
      id: "",
      dateTime: "",
      showMsgBox: false,
      showDetail: false,
      showForm: false,
      msgBoxIndex: 1,
      msgBox: {},
      form: JSON.parse(JSON.stringify(defaultForm)),
      pageSizes: [10, 20, 30, 50],
      total: 0,
      price: 0,
      list: [],
      activityList: [],
      marketActivityList: [],
      parkingType: {
        STANDARD: "标准车位",
        SUB_MOTHER: "子母车位",
        RECHARGEABLE: "充电车位",
        BILAYER: "双层车位"
      },
      orderStatusList: [
        {
          label: "未支付",
          value: 1
        },
        {
          label: "已支付",
          value: 5
        },
        {
          label: "已取消",
          value: 10
        }
      ],
      orderStatusObj: {
        1: "未支付",
        5: "已支付",
        10: "已取消",
        15: "已取消"
      },
      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      query: {
        endDate: "",
        keyWord: "",
        orderStatus: "",
        pageNum: 1,
        pageSize: 10,
        startDate: "",
        estateId: 0,
        openQuotationActivityId: "",
        marketActivityId: "", //营销活动
        loan: false //是否申请贷款
      },
      showFooter: true,
      NOText: "",
      OKText: ""
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  watch: {
    "query.openQuotationActivityId"() {
      this.getMarketActivity();
    },
    dateTime(e) {
      let dateList = e || ["", ""];
      let [startDate, endDate] = dateList;
      this.query.startDate = startDate;
      this.query.endDate = endDate;
      this.search();
    }
  },
  created() {
    this.orderList();
    this.getActivityList();
  },
  methods: {
    async getActivityList() {
      this.activityList = (await getActivityList(this.estateId)) || [];
    },
    async getMarketActivity() {
      this.marketActivityList =
        (await getMarketActivity(this.query.openQuotationActivityId)) || [];
    },
    chooseDetail(item) {
      this.activeItem = item;
      this.showDetail = true;
    },
    OKFunc() {
      if (this.OKText == "确认收款") {
        this.confirmPay(this.activeItem);
      }
      if (this.OKText == "恢复订单") {
        this.recoverOrder(this.activeItem.id);
      }
    },
    NOFunc() {
      this.cancelOrder(this.activeItem.id);
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.orderList();
    },
    handleCurrentChange(val) {
      this.query.pageNum = val;
      this.orderList();
    },
    search() {
      this.query.pageNum = 1;
      this.orderList();
    },
    async orderList() {
      this.query.estateId = this.estateId;
      if (!this.query.loan) {
        this.query.loan = null;
      }
      const res = await orderList(this.query);
      const { total, list } = res;
      this.total = total;
      this.list = list;
    },
    exportOrder() {
      const { query, userInfo } = this;
      const { token, userId } = userInfo;
      let url = "";
      for (let key in query) {
        if (query[key]) {
          url += `${key}=${query[key]}&`;
        }
      }
      url = `${EXPORT_ORDER_URL}?${url}token=${token}&userId=${userId}`;
      window.location.href = url;
    },
    async recoverOrder(id) {
      const res = await recoverOrder(id);
      const { expireMinute, code } = res;
      if (Number(code) === 0) {
        this.msgBoxIndex = 1;
        this.getMsgBox(expireMinute);
      } else {
        const errs = {
          1: "非可售状态",
          2: "已被锁定",
          3: "不支持认购"
        };

        let name = errs[code];
        this.msgBoxIndex = 2;
        this.getMsgBox(name);
      }
      this.orderList();
    },
    async cancelOrder(id) {
      const res = await cancelOrder(id);
      if (res) {
        this.msgBoxIndex = 0;
        this.getMsgBox();
        this.orderList();
      }
    },
    confirmPay(item) {
      this.form = JSON.parse(JSON.stringify(defaultForm));
      this.price = item.price;
      this.id = item.id;
      this.showForm = true;
    },
    async payedOrder() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const res = await payedOrder(this.id, this.form);
          if (res) {
            this.orderList();
          }
        } else {
          this.$showError("请正确填充表单");
          return false;
        }
      });
    },
    getMsgBox(name) {
      const msgBoxList = [
        {
          status: true,
          title: "取消成功",
          msg: "订单编号为已取消，订单关联的房源已释放"
        },
        {
          status: true,
          title: "订单恢复成功",
          msg: `房源已重新锁定，请在<span class='red'>${name}分钟</span>内完成购房定金支付！`
        },
        {
          status: false,
          title: "很遗憾，恢复失败",
          msg: `当前房源${name}，无法恢复当前订单`
        }
      ];
      this.msgBox = msgBoxList[this.msgBoxIndex];
      this.showMsgBox = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.order {
  background-color: $white;
  padding: 24px;
}
.filter {
  ::v-deep {
    .el-select {
      width: 180px;
    }
    .el-date-editor {
      width: 240px;
    }
  }
}
</style>

<style lang="scss">
.order {
  .el-input-group__append {
    font-size: 13px;
    color: $color3;
  }
}
</style>
