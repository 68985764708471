var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "在线选车位·认购订单",
      "visible": _vm.visible,
      "width": "760px",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "x3 header"
  }, [_c('div', [_vm._v(" 订单信息 ")]), _c('div', {
    staticClass: "price"
  }, [_vm._v("NO. " + _vm._s(_vm.detail.orderNo))])]), _c('div', {
    staticClass: "list-item"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("开盘活动")]), _c('table', {
    attrs: {
      "border": "1",
      "cellspacing": "0",
      "cellpadding": "0"
    }
  }, [_c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("开盘活动")]), _c('td', [_vm._v(_vm._s(_vm.detail.activityName))]), _c('td', {
    staticClass: "th"
  }, [_vm._v("认购方式")]), _c('td', [_vm._v(_vm._s(_vm.detail.orderBuyType === 2 ? "竞拍认购" : "直接认购"))])]), _c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("认购金")]), _c('td', [_c('span', {
    staticClass: "red price"
  }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.detail.orderPrice)) + " ")])]), _c('td', {
    staticClass: "th"
  }, [_vm._v(" 支付方式 ")]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.detail.orderWayDesc) + " ")])])]), _c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("认购总额")]), _c('td', {
    staticStyle: {
      "color": "red"
    }
  }, [_c('span', {
    staticClass: "price"
  }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.detail.totalPrice)) + " ")])]), _c('td', {
    staticClass: "th"
  }, [_vm._v("优惠活动")]), _c('td', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.giftBagName || (_vm.detail.orderTotalPriceDesc === "销售总价" ? "无" : _vm.detail.orderTotalPriceDesc)) + " ")])]), _c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("金融方案")]), _c('td', [_vm._v(" " + _vm._s(_vm.detail.loan ? "已申请" : "-") + " ")]), _c('td', {
    staticClass: "th"
  }, [_vm._v("认购时间")]), _c('td', [_vm._v(" " + _vm._s(_vm.detail.inDate || "-") + " ")])])])]), Object.keys(_vm.detail).length ? _c('div', {
    staticClass: "list-item"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("认购车位")]), _c('table', {
    attrs: {
      "border": "1",
      "cellspacing": "0",
      "cellpadding": "0"
    }
  }, [_c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("车位号")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("link")([_vm.detail.parkingHouseInfo.floorName, _vm.detail.parkingHouseInfo.areaName, _vm.detail.parkingHouseInfo.parkingPlaceNo])) + "号车位 ")]), _c('td', {
    staticClass: "th"
  }, [_vm._v("类型")]), _c('td', [_vm._v(" " + _vm._s(_vm.detail.parkingHouseInfo.parkingPlaceTypeDesc) + " ")])]), _c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("建面")]), _c('td', [_vm._v(_vm._s(_vm.detail.parkingHouseInfo.buildingArea) + "㎡")]), _c('td', {
    staticClass: "th"
  }, [_vm._v("实得")]), _vm.detail.parkingHouseInfo.actualArea ? _c('td', [_vm._v(" " + _vm._s(_vm.detail.parkingHouseInfo.actualArea) + "㎡ ")]) : _c('td', [_vm._v("-")])]), _c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("价格")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.$calc.accMulti(_vm.detail.parkingHouseInfo.discountAfterPrice, 10000))) + " ")])]), _c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("备注")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(_vm._s(_vm.detail.parkingHouseInfo.description || "-"))])])])]) : _vm._e(), _c('div', {
    staticClass: "list-item"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("认购客户")]), _c('table', {
    attrs: {
      "border": "1",
      "cellspacing": "0",
      "cellpadding": "0"
    }
  }, [_c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("姓名")]), _c('td', [_vm._v(_vm._s(_vm.detail.customerName))]), _c('td', {
    staticClass: "th"
  }, [_vm._v("身份证号")]), _c('td', [_vm._v(_vm._s(_vm.detail.customerIdcard))])]), _c('tr', [_c('td', {
    staticClass: "th"
  }, [_vm._v("手机号码")]), _c('td', [_vm._v(_vm._s(_vm._f("formatPhone")(_vm.detail.customerPhone)))]), _c('td', {
    staticClass: "th"
  }, [_vm._v("认购账号")]), _c('td', [_c('div', {
    staticClass: "acc-info"
  }, [_c('el-image', {
    staticClass: "avatar",
    attrs: {
      "src": _vm.detail.subscriptionUserAvatar,
      "fit": "cover"
    }
  }), _vm._v(_vm._s(_vm.detail.subscriptionUserName) + " ")], 1)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }