import request from "@/utils/request";

// 获取订单列表
export function saveOrUpdate(data) {
  return request({
    url: `/api/v1/platform/marketActivity/saveOrUpdate`,
    method: "post",
    data
  });
}
/**
 * 获取活动类别
 */
export function getActivityList(estateId, params) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/${estateId}/list`,
    method: "get",
    params
  });
}

/**
 * 获取营销列表
 */
export function getMarketActivity(activityId, params) {
  return request({
    url: `/api/v1/platform/marketActivity/list/${activityId}`,
    method: "get",
    params
  });
}
/**
 * 营销活动->产品/服务 新增
 */

export function productSaveOrUpdate(data) {
  return request({
    url: "/api/v1/platform/marketActivity/productSaveOrUpdate",
    method: "post",
    data
  });
}

/**
 * 营销活动->产品/服务 列表
 */
export function getProductList(marketId) {
  return request({
    url: `/api/v1/platform/marketActivity/productList/${marketId}`,
    method: "get"
  });
}

/**
 * 营销活动->产品/服务 删除
 */

export function productDelete(id, data) {
  return request({
    url: `/api/v1/platform/marketActivity/productDelete/${id}`,
    method: "post",
    data
  });
}

/**
 * 上下架
 */

export function statusSwitch(id, data) {
  return request({
    url: `/api/v1/platform/marketActivity/statusSwitch/${id}`,
    method: "post",
    data
  });
}

/**
 * 删除营销活动
 */

export function marketActivityDelete(id) {
  return request({
    url: `/api/v1/platform/marketActivity/delete/${id}`,
    method: "post"
  });
}

// 查询竞拍
export function getBiddingList(data) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/list`,
    method: "get",
    data
  });
}

//添加修改竞拍
export function editBidding(data) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/create`,
    method: "post",
    data
  });
}

//继续竞拍/重新挂拍
export function proceedBidding(data) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/proceed/${data.id}`,
    method: "post",
    data
  });
}

//查询车位-房源
export function selectBidding(openQuotationActivityId, params) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/parking/house/select/${openQuotationActivityId}`,
    method: "get",
    params
  });
}

//删除
export function removeBidding(id) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/remove/${id}`,
    method: "post"
  });
}

//挂拍
export function biddingBidding(id) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/bidding/${id}`,
    method: "post"
  });
}

//暂缓
export function pauseBidding(id, data) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/pause/${id}`,
    method: "post",
    data
  });
}

//撤回
export function withdrawBidding(id, data) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/withdraw/${id}`,
    method: "post",
    data
  });
}

//添加修改竞拍介绍
export function remarkBidding(id, data) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/remark/${id}`,
    method: "post",
    data
  });
}

// 报名记录-竞拍
export function appointmentList(biddingId, params) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/appointment/${biddingId}/list`,
    method: "get",
    params
  });
}

// 报名记录-收款
export function managePay(id, data) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/pay/${id}`,
    method: "post",
    data
  });
}

// 报名记录-退款
export function manageRefund(id) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/refund/${id}`,
    method: "post"
  });
}

// 报名记录-删除
export function manageRemove(id) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/appointment/remove/${id}`,
    method: "post",
    data: {
      reamk: ""
    }
  });
}

// 竞拍列表(PC版)
export function recordPageForPC(data) {
  return request({
    url: `/api/v1/fxb/bid/recordPageForPC`,
    method: "get",
    data
  });
}

// 获取竞拍介绍
export function getRemark(id) {
  return request({
    url: `/api/v1/platform/openauotation/activity/bidding/manage/remark/${id}`,
    method: "get"
  });
}
