<template>
  <el-dialog
    title="在线选车位·认购订单"
    :visible.sync="visible"
    width="760px"
    :before-close="handleClose"
  >
    <div class="x3 header">
      <div>
        订单信息
      </div>
      <div class="price">NO. {{ detail.orderNo }}</div>
    </div>

    <div class="list-item">
      <div class="title">开盘活动</div>
      <table border="1" cellspacing="0" cellpadding="0">
        <tr>
          <td class="th">开盘活动</td>
          <td>{{ detail.activityName }}</td>
          <td class="th">认购方式</td>
          <td>{{ detail.orderBuyType === 2 ? "竞拍认购" : "直接认购" }}</td>
        </tr>
        <tr>
          <td class="th">认购金</td>
          <td>
            <span class="red price"
              >¥{{ detail.orderPrice | toThousands }}
            </span>
          </td>
          <td class="th">
            支付方式
          </td>
          <td>
            <span>{{ detail.orderWayDesc }} </span>
          </td>
        </tr>
        <tr>
          <td class="th">认购总额</td>
          <td style="color:red;">
            <span class="price">¥{{ detail.totalPrice | toThousands }} </span>
          </td>
          <td class="th">优惠活动</td>
          <td style="color:red;">
            {{
              detail.giftBagName ||
                (detail.orderTotalPriceDesc === "销售总价"
                  ? "无"
                  : detail.orderTotalPriceDesc)
            }}
          </td>
        </tr>
        <tr>
          <td class="th">金融方案</td>
          <td>
            {{ detail.loan ? "已申请" : "-" }}
          </td>
          <td class="th">认购时间</td>
          <td>
            {{ detail.inDate || "-" }}
          </td>
        </tr>
      </table>
    </div>

    <div class="list-item" v-if="Object.keys(detail).length">
      <div class="title">认购车位</div>
      <table border="1" cellspacing="0" cellpadding="0">
        <tr>
          <td class="th">车位号</td>
          <td>
            {{
              [
                detail.parkingHouseInfo.floorName,
                detail.parkingHouseInfo.areaName,
                detail.parkingHouseInfo.parkingPlaceNo
              ] | link
            }}号车位
          </td>
          <td class="th">类型</td>
          <td>
            {{ detail.parkingHouseInfo.parkingPlaceTypeDesc }}
          </td>
        </tr>
        <tr>
          <td class="th">建面</td>
          <td>{{ detail.parkingHouseInfo.buildingArea }}㎡</td>
          <td class="th">实得</td>
          <td v-if="detail.parkingHouseInfo.actualArea">
            {{ detail.parkingHouseInfo.actualArea }}㎡
          </td>
          <td v-else>-</td>
        </tr>
        <tr>
          <td class="th">价格</td>
          <td colspan="3">
            ¥{{
              $calc.accMulti(detail.parkingHouseInfo.discountAfterPrice, 10000)
                | toThousands
            }}
          </td>
        </tr>
        <tr>
          <td class="th">备注</td>
          <td colspan="3">{{ detail.parkingHouseInfo.description || "-" }}</td>
        </tr>
      </table>
    </div>

    <div class="list-item">
      <div class="title">认购客户</div>
      <table border="1" cellspacing="0" cellpadding="0">
        <tr>
          <td class="th">姓名</td>
          <td>{{ detail.customerName }}</td>
          <td class="th">身份证号</td>
          <td>{{ detail.customerIdcard }}</td>
        </tr>
        <tr>
          <td class="th">手机号码</td>
          <td>{{ detail.customerPhone | formatPhone }}</td>
          <td class="th">认购账号</td>
          <td>
            <div class="acc-info">
              <el-image
                :src="detail.subscriptionUserAvatar"
                fit="cover"
                class="avatar"
              ></el-image
              >{{ detail.subscriptionUserName }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "visible",
    event: "input"
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleClose() {
      this.$emit("input");
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  td {
    padding-left: 20px;
    border-color: #eee;
  }
  .th {
    padding-left: 0;
    width: 144px;
    height: 45px;
    text-align: center;
    background: #f8f8f8;
  }
}
.header {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 18px;
}

.list-item {
  margin-bottom: 10px;
  .title {
    margin: 10px 0;
    border-left: 4px solid #0f9fff;
    padding-left: 10px;
  }
}
.price {
  font-weight: bold;
  color: red;
}
.acc-info {
  @include flex-yc;
  .avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
  }
}
</style>
